<template>
  <div class="modal is-active" v-if="EditDestination && Config">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add/Edit Destination</p>
        <button
          class="delete"
          aria-label="close"
          v-on:click="cancelDestinationDialog"
        ></button>
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input
              v-model="EditDestination.Name"
              class="input"
              type="text"
              placeholder="Text input"
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Schedule (as CRON Expression in UK Time)</label>
          <div class="control">
            <input
              v-model="EditDestination.ScheduleCRON"
              class="input"
              type="text"
              placeholder="Enter a cron expression for a schedule"
            />
          </div>
        </div>

        <div class="field">{{ cronAsWords(EditDestination.ScheduleCRON) }}</div>

        <div class="field is-size-6">
          We use a 5 part CRON expression to set a schedule. The parts relate to
          "minutes", "hours", "day of month", "month" and finally "day of week".
          <br />
          <a target="_blank" href="http://corntab.com/?c=30_18_*_*_MON"
            >Help building CRON expressions</a
          >
          <br />If you are unsure, please ask a developer for help.
        </div>

        <div class="field">
          <label class="label">Data Format</label>
          <div class="control">
            <div class="select">
              <select v-model="EditDestination.FulfilmentClassName">
                <option value>Select dropdown</option>
                <option
                  v-for="c in Config.DataTransformClasses"
                  :value="c"
                  :key="c"
                >
                  {{ c }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Filename (Optional)</label>
          <div class="control">
            <input
              v-model="EditDestination.Filename"
              class="input"
              type="text"
              placeholder="dd-MM-yyyy'.txt'"
            />
          </div>
        </div>

        <div class="field is-size-6">
          <router-link to="/#FileFormat" target="_blank"
            >How to use filename field</router-link
          >
        </div>

        <div class="field">
          <label class="label" for="custom">Custom</label>
          <p class="is-size-7">
            Extra information (in json) here which is used for things like
            prePaidCode
          </p>
          <div class="control">
            <textarea
              name="custom"
              style="height: 100px"
              class="input"
              type="text"
              v-model="EditDestination.Custom"
            ></textarea>
          </div>
          <p>
            Is valid custom data:
            <span v-if="customIsValidJson" class="tag is-success cur">Yes</span>
            <span v-else class="tag is-danger cur">No</span>
          </p>
        </div>

        <div class="field">
          <label class="label">Contact Group</label>
          <div class="control">
            <div class="select">
              <select v-model="EditDestination.ContactGroupId">
                <option :value="null">None Selected...</option>
                <option v-for="c in ContactGroups" :value="c.Id" :key="c.Id">
                  {{ c.Name }}
                </option>
              </select>
            </div>
            &nbsp; &nbsp;
            <button class="button is-primary" v-on:click="addContactGroup">
              Create New
            </button>
          </div>
        </div>

        <div class="box" v-if="EditDestination.ContactGroupId">
          <contact-group-table
            :contact-group-id="EditDestination.ContactGroupId"
            :to-update.sync="contactGroupTableToBeUpdated"
          ></contact-group-table
          >&nbsp;
          <div class="level" style="margin-top: 10px">
            <div class="level-left"></div>
            <!-- Right side -->
            <div class="level-right">
              <button
                class="button is-primary is-pulled-right level-item"
                v-if="EditDestination.ContactGroupId !== null"
                v-on:click="editContactGroup(EditDestination.ContactGroupId)"
              >
                Edit this Contact Group
              </button>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Notification Email</label>
          <div class="control">
            <input
              v-model="EditDestination.NotificationAddress"
              class="input"
              type="text"
              placeholder="Comma seperated list of emails"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Send when no sales?</label>
          <b-switch v-model="EditDestination.SendEmpty"></b-switch>
          <span>{{ EditDestination.SendEmpty ? "Yes" : "No" }}</span>
        </div>

        <div class="field">
          <label class="label">Notes and Contact Information</label>
          <div class="control">
            <textarea
              class="textarea"
              v-model="EditDestination.NotesAndContactInformation"
              type="text"
              placeholder="Destination information and contact details"
            ></textarea>
          </div>
        </div>

        <div class="field">
          <label class="label">Method Type</label>
          <div class="control">
            <div class="select">
              <select v-model="EditDestination.MethodType">
                <option
                  v-for="(c, index) in Config.MethodType"
                  :value="index"
                  :key="index"
                >
                  {{ c }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <vue-form-generator
          :schema="MethodTypeForm[EditDestination.MethodType]"
          :model="EditDestination.SettingsObject.SendSettings"
        ></vue-form-generator>

        <div class="field">
          <label class="label">Format Type</label>
          <div class="control">
            <div class="select">
              <select v-model="EditDestination.FormatType">
                <option
                  v-for="(c, index) in Config.FormatType"
                  :value="index"
                  :key="index"
                >
                  {{ c }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <vue-form-generator
          :schema="FormatTypeForm[EditDestination.FormatType]"
          :model="EditDestination.SettingsObject.FormatSettings"
        ></vue-form-generator>

        <div class="field">
          <label class="label">Protection Type</label>
          <div class="control">
            <div class="select">
              <select v-model="EditDestination.Protection">
                <option
                  v-for="(c, index) in Config.ProtectionType"
                  :value="index"
                  :key="index"
                >
                  {{ c }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="field" v-if="EditDestination.Protection == '1'">
          <label class="label">Password</label>
          <div class="control">
            <input
              type="text"
              v-model="
                EditDestination.SettingsObject.ProtectionSettings.password
              "
              id="password"
              placeholder="Password"
              class="input"
            />
            <div v-if="EditDestination.Protection == '1'" :key="tempPassword">
              <a v-on:click="generateRandomPasswordForZip()"
                >Generate Random Password</a
              >
            </div>
          </div>
        </div>

        <div class="field" v-else-if="EditDestination.Protection == '2'">
          <label class="label">Encryption Key Path</label>
          <div class="control">
            <input
              type="text"
              v-model="
                EditDestination.SettingsObject.ProtectionSettings
                  .EncryptionKeyPath
              "
              id="EncryptionKeyPath"
              placeholder="GPG Key path"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Enabled?</label>
          <b-switch v-model="EditDestination.Enabled"></b-switch>
          <span>{{ EditDestination.Enabled ? "Yes" : "No" }}</span>
        </div>

        <validation-errors :model-state="validationErrors"></validation-errors>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :disabled="!customIsValidJson"
          class="is-success"
          v-if="isNew || true"
          :loading="isLoading"
          v-on:click="saveChanges"
          >Save</b-button
        >
        <span v-else>Cannot edit existing destinations currently&nbsp;</span>
        <button class="button" v-on:click="cancelDestinationDialog">
          Cancel
        </button>
      </footer>
    </div>

    <contact-group-insert-or-update
      v-if="EditContactGroup"
      :contact-group="EditContactGroup"
      v-on:close="closeContactGroupDialog"
      v-on:add="addContactGroupSuccess"
      fixed-group-type="Destination"
    ></contact-group-insert-or-update>
  </div>
</template>

<script>
import cronstrue from "cronstrue";

export default {
  name: "batchDestinationInsertOrUpdate",
  components: {
    validationErrors: () =>
      import("../components/molecules/validationErrors.vue"),
    contactGroupInsertOrUpdate: () =>
      import("./organisms/contactGroupInsertOrUpdate.vue"),
    contactGroupTable: () => import("./organisms/contactGroupTable.vue"),
  },
  props: {
    EditDestination: Object,
  },
  data: function() {
    return {
      validationErrors: null,
      tempPassword: "",
      isLoading: false,
      APIUrl: process.env.VUE_APP_APIURL,
      Config: null,
      ContactGroups: null,
      EditContactGroup: null,
      contactGroupTableToBeUpdated: false,
      CustomForm: {
        groups: [
          {
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Pre Paid Code",
                model: "prePaidCode",
                id: "prePaidCode",
                placeholder: "prePaidCode used on Some CDS formats",
              },
            ],
          },
        ],
      },

      FormatTypeForm: {
        0: {
          //CSV
          groups: [
            {
              fields: [
                {
                  type: "switch",
                  label: "Hide Headers",
                  model: "HideHeaders",
                  id: "HideHeaders",
                  default: false,
                },
              ],
            },
          ],
        },
        1: {}, //API
        2: {}, //TXT
      },
      // ProtectionType: {
      //   0: {},
      //   1: {
      //     //zip password
      //     groups: [
      //       {
      //         fields: [
      //           {
      //             type: "input",
      //             inputType: "text",
      //             label: "Password",
      //             model: "password",
      //             id: "password",
      //             placeholder: "Zip password",
      //             required: true
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   2: {
      //     //gpg
      //     groups: [
      //       {
      //         fields: [
      //           {
      //             type: "input",
      //             inputType: "text",
      //             label: "EncryptionKeyPath",
      //             model: "EncryptionKeyPath",
      //             id: "EncryptionKeyPath",
      //             placeholder: "GPG Key path",
      //             required: true
      //           }
      //         ]
      //       }
      //     ]
      //   }
      // },
      MethodTypeForm: {
        0: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "Host",
                  model: "host",
                  id: "host",
                  placeholder: "API Endpoint",
                  required: true,
                },
              ],
            },
          ],
        },
        1: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "Host",
                  model: "host",
                  id: "host",
                  placeholder: "URL to FTP",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Username",
                  model: "username",
                  required: true,
                  placeholder: "ftp username",
                },
                {
                  type: "custompassword",
                  label: "Password",
                  model: "password",
                  required: true,
                  validator: "string",
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Directory",
                  model: "directory",
                  placeholder: "default directory",
                },
                {
                  type: "select",
                  label: "Encryption Mode",
                  model: "encryptionMode",
                  values: ["implicit", "explicit", "none"],
                },
              ],
            },
          ],
        },
        2: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "Host",
                  model: "host",
                  id: "host",
                  placeholder: "URL to SFTP",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Username",
                  model: "username",
                  required: true,
                  placeholder: "ftp username",
                },
                {
                  type: "custompassword",
                  label: "Password",
                  model: "password",
                  required: true,
                  validator: "string",
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Directory",
                  model: "directory",
                  placeholder: "default directory",
                },
              ],
            },
          ],
        },
        3: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "Email",
                  model: "email",
                  id: "email",
                  placeholder: "Comma seperate receipient emails",
                  required: true,
                },
              ],
            },
          ],
        },
        4: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "Client Id",
                  model: "clientId",
                  id: "clientId",
                  placeholder: "Client Id",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Sales Org",
                  model: "salesOrg",
                  id: "salesOrg",
                  placeholder: "Sales Org",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Username",
                  model: "username",
                  required: true,
                  placeholder: "API User name",
                },
                {
                  type: "input",
                  inputType: "password",
                  label: "Password",
                  model: "password",
                  placeholder: "API Password",
                  required: true,
                  validator: "string",
                },
              ],
            },
          ],
        },
        5: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "Myriad Endpoint",
                  model: "MyriadEndpoint",
                  id: "MyriadEndpoint",
                  placeholder: "IP and Port",
                  required: true,
                },
              ],
            },
          ],
        },
        6: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "App Id",
                  model: "appId",
                  id: "appId",
                  placeholder: "App Id",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "App Url",
                  model: "appUrl",
                  id: "appUrl",
                  placeholder: "App Url",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Password",
                  model: "password",
                  id: "password",
                  placeholder: "Password",
                  required: true,
                },
              ],
            },
          ],
        },
        7: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "Brand Abbreviation",
                  model: "brandAbbreviation",
                  id: "brandAbbreviation",
                  placeholder: "e.g PWXYZ",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Api Key",
                  model: "apiKey",
                  id: "apiKey",
                  placeholder: "Guid like",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "InputId",
                  model: "inputId",
                  id: "inputId",
                  placeholder: "inputId",
                  required: true,
                },
              ],
            },
          ],
        },
        8: {
          groups: [
            {
              fields: [
                {
                  type: "input",
                  inputType: "text",
                  label: "BucketName",
                  model: "bucketName",
                  id: "bucketName",
                  placeholder: "Name of te bucket",
                  required: true,
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "AccessKeyId",
                  model: "accessKeyId",
                  required: true,
                  placeholder: "",
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "SecretAccessKey",
                  model: "secretAccessKey",
                  required: true,
                  placeholder: "",
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Region",
                  model: "region",
                  required: true,
                  placeholder: "e.g. `us-west-2`, `us-west-1`",
                },
                {
                  type: "input",
                  inputType: "text",
                  label: "Directory",
                  model: "directory",
                  placeholder: "default directory",
                },
              ],
            },
          ],
        },
      },
    };
  },
  created() {
    this.loadConfig();
    this.loadContactGroups();
  },
  methods: {
    loadConfig() {
      this.axios.get(this.APIUrl + "/destination/config").then((response) => {
        this.Config = response.data;
      });
    },
    loadContactGroups() {
      this.axios
        .get(this.APIUrl + "/contact-group/basic?groupType=Destination")
        .then((response) => {
          this.ContactGroups = response.data;
        });
    },
    close() {
      //emit close!
      this.$emit("close");
    },
    saveChanges() {
      console.log(this.Destination);

      var url = this.APIUrl + "/destination/";
      this.isLoading = true;
      // this.Destination.SettingsObject = "";

      var dest = {
        Id: this.EditDestination.Id,
        Name: this.EditDestination.Name,
        Enabled: this.EditDestination.Enabled,
        FulfilmentClassName: this.EditDestination.FulfilmentClassName,
        Filename: this.EditDestination.Filename,
        MethodType: parseInt(this.EditDestination.MethodType),
        FormatType: this.EditDestination.FormatType,
        Protection: this.EditDestination.Protection,
        SendEmpty: this.EditDestination.SendEmpty,
        ScheduleCRON: this.EditDestination.ScheduleCRON,
        NotificationAddress: this.EditDestination.NotificationAddress,
        NotesAndContactInformation: this.EditDestination
          .NotesAndContactInformation,
        SettingsObject: this.EditDestination.SettingsObject,
        Custom: this.EditDestination.Custom,
        ContactGroupId: this.EditDestination.ContactGroupId,
        //ProductsLinked
        //Settings: this.Destination.Settings
      };

      //EMAIL
      if (dest.MethodType === 3) {
        //and is a string (not array which means its unedited)
        if (typeof dest.SettingsObject.SendSettings.email === "string") {
          dest.SettingsObject.SendSettings.email = dest.SettingsObject.SendSettings.email
            .split(",")
            .map(function(item) {
              return item.trim();
            });
        }
      }

      var self = this;
      self.validationErrors = null;

      //save changes
      this.axios
        .post(url, dest)
        .then((response) => {
          var isNew = self.isNew;
          this.Destination = response.data;
          this.isLoading = false;

          //show toast!
          this.$toast.open(
            "Successfully " +
              (isNew ? "updated" : "created") +
              " <b>'" +
              response.data.Name +
              "'</b>"
          );

          //emit changes back
          if (isNew) this.$emit("add", response.data);
          else this.$emit("close");
        })
        .catch(function(re) {
          self.isLoading = false;

          if (re.response.status === 400) {
            self.$toast.open({
              message:
                "Saving failed the validation check, please see details below",
              duration: 2500,
              type: "is-danger",
            });
            self.validationErrors = re.response.data;
          } else {
            self.catchError(re);
          }
        });
    },
    cancelDestinationDialog() {
      this.$emit("close");
    },
    cronAsWords(str) {
      return cronstrue.toString(str);
    },
    generatePassword() {
      var length = 12,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    generateRandomPasswordForZip() {
      // this.testPass = this.generatePassword();
      this.tempPassword = this.generatePassword();
      this.EditDestination.SettingsObject.ProtectionSettings.password = this.tempPassword;
    },

    editContactGroup(contactGroupId) {
      //load from the API
      this.isLoading = true;
      var url = this.APIUrl + "/contact-group/" + contactGroupId;
      this.axios.get(url).then((response) => {
        this.EditContactGroup = response.data;
        this.isLoading = false;
      });
      //
    },
    addContactGroup() {
      this.EditContactGroup = {
        Id: null,
        Name: "",
        GroupType: 0,
        Contacts: [],
      };
    },

    addContactGroupSuccess(contactGroup) {
      this.EditDestination.ContactGroupId = contactGroup.Id;
      this.closeContactGroupDialog();
    },
    closeContactGroupDialog() {
      this.loadContactGroups(); //reload table
      this.contactGroupTableToBeUpdated = true;
      this.EditContactGroup = null;
    },
  },
  computed: {
    customIsValidJson() {
      if (
        this.EditDestination.Custom &&
        this.EditDestination.Custom.length > 0
      ) {
        try {
          JSON.parse(this.EditDestination.Custom);
        } catch (e) {
          return false;
        }
      }
      return true;
    },
    isNew: function() {
      return this.EditDestination.Id == null;
    },
  },
};
</script>
